.quick-view-button a.btn {
    width: 100% !important;
}
.template-collection .collection-btm-desc .faq_heading {
   /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";*/
    font-weight: 500;
    font-size: 24px;
    margin: 0 0 20px;
    line-height: 1.5;
    letter-spacing: 0;
    text-transform: none;
}
.collection-btm-desc {
    display: block;
    margin-top: 40px;
    margin-bottom: 40px;
}
.template-collection .collection-btm-desc p {
    /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";*/
}
.template-collection .collection-btm-desc img.cb-img{
    width: 100%;
    margin-top: 15px;
}
.collection_page_header {
    display: block !important;
    text-align: center;
}
.collection_page_header .rte.collection_desc {
    /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";*/
    line-height: 1.25;
}
.collection_page_header .collection_page_title {
    padding-top: 15px;
}
.filters-toolbar__item-child {
    text-align: right;
    margin-bottom: 30px;
}
ul.collection_blog_listing li {
    margin-bottom: 20px;
}
ul.collection_blog_listing li a {
    font-weight: 600;
    border-bottom: 1px solid black;
}
ul.collection_blog_listing li p {
    padding-top: 4px;
    width: 100%;
}
ul.collection_blog_listing li a:hover {
    border-bottom: 1px solid transparent;
    opacity: 0.9;
}
.template-collection .collection-btm-desc p.cb-img-alt {
    text-transform: none;
}
.ab_shipping_icon {
    vertical-align: middle;
    margin: -2px 5px 0px 0;
    width: 16px;
    height: 16px;
}
header.site-header, 
header .site-header__mobile-nav {
    background: #727869;
}
.site-header .site-header__icon.site-header__cart img.cart_logo {
    width: 28px;
    padding-top: 0px;
    height: 28px;
    margin-right: -2px;
    margin-left: -2px;
    vertical-align: bottom;
}
.site-header .site-header__icons-wrapper img.flag_logo {
    width: 25px;
}
body.gm-menu-installed .gm-menu ul.gm-grid+p {
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important;
}
.product-single .product__price-right-bedge ul li {
    font-size: 15px;
}
.product-single .product-form__item label {
    font-weight: 400 !important;
} 
.product-single .gpo-app .gpo-label span.label-content, 
.product-single .gpo-app .gpo-label span.addOn, 
.product-single .gpo-app .gpo-label.required span.label-content::after,
.product-single .gpo-app .gpo-select, .product-single .gpo-app .gpo-select select {
    /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;*/
    font-weight: 400 !important;
    font-size: 15px;
}
.product-single .gpo-app .gpo-label {
    margin-bottom: 10px;
}
.product-single .gpo-app {
    margin-top: 0;
    margin-bottom: -7px; 
}
.product-single .gpo-tooltip__title {
    font-size: 15px;
    /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";*/
}
.product-single .gpo-app .gpo-label.required span.label-content::after {
    color: #000;
}
.product-single .product-form__item label {
    font-size: 15px;
    margin-bottom: 10px;
}
.product-single .product-form__qty .product-form__item label {
    line-height: normal;
}
.product-form.product-form-product-template.product-form--variant-sold-out .btn.product-form__cart-submit{
    display: block !important;
}
.product-form.product-form-product-template.product-form--variant-sold-out .btn.product-form__cart-submit:nth-child(2) {
    display: none !important;
}
.product-form.product-form-product-template.product-form--variant-sold-out .product-form__controls-group.product-form__controls-group--submit {
    margin-top: 20px;
}
.product-single .product-form__controls-group~.product-form__controls-group--submit {
    margin-top: 20px;
}
.product-form.product-form-product-template.product-form--variant-sold-out .product-form__controls-group.product-form__controls-group--submit .product-form__item.product-form__item--submit {
    margin-right: 0;
    margin-top: 10px;
}
.product-form.product-form-product-template .product-form__controls-group.product-form__controls-group--submit .product-form__item.product-form__item--submit {
    margin-right: 0;
    margin-top: 10px;
}
.product-single .product-form {
    padding-top: 1.5rem;
}
.product-single .product-single__title {
    margin-bottom: 0;
}
.product-single__meta .product__price {
    line-height: 1.35;
}
.product-single .selector-wrapper.product-form__item:last-of-type {
    margin-bottom: 15px;
}
.product-single .gpo-app .gpo-total-text {
    margin-bottom: 20px;
}
.product-single .product-form__cart-submit{
    margin-top: 0px;
}
.product-single .product-form__item--no-variants .product-form__cart-submit{
    margin-top: 0px;
}
.product-single__price-wrap .price__regular dd .product__policies.rte {
    margin-bottom: -8px;
}



.collection_page_header .rte.collection_desc{
    margin-bottom: 28px;
}
body.template-collection .main-content {
    padding-top: 23px;
}
body.template-collection .collection_page_title {
    padding-top: 0;
    padding-bottom: 21px;
    margin-bottom: 0;
}
.collection_related .cr-grid .grid__item {
    padding-left: 20px;
}
.collection_related {
    max-width: 650px;
    margin: 28px auto 0px;
}
.collection_related .cr-grid {
    padding-top: 30px;
    padding-bottom: 14px;
}
p.collection_related_heading {
    margin-bottom: 0;
}

p.collection_related_heading span {
    background: #747A68;
    color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    display: inline-block;
    width: 150px;
    height: 30px;
    line-height: 30px;
    border-radius: 50px;
    font-size: 16px;
}
.collection_related a {
    display: block;
    width: 100%;
    /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";*/
    font-size: 16px;
}
.collection_related a:hover img{
  opacity: 0.9;
}
.product-card.has-secondary:hover img.secondary {
   /* display: block;*/
}

@media only screen and (max-width: 749px) {
  .collection_related .cr-grid .grid__item:nth-child(1),
  .collection_related .cr-grid .grid__item:nth-child(2){
      margin-bottom: 14px;
  }
  .site-header .site-header__icons-wrapper img.flag_logo {
      display: none;
  }
  .site-header .site-header__icon.site-header__cart #CartCount > span {
      padding-right: 0;
  }
  .site-header  .site-header__icon {
      padding: 5px 5px;
  }
  .site-header .site-header__icon.site-header__cart img.cart_logo {
      width: 26px;
      padding-top: 2px;
      height: 26px;
      margin-right: 25px;
      margin-left: -2px;
      vertical-align: sub;
  }
  .site-header .site-header__icons .site-header__icons-wrapper {
      padding-top: 0;
  }
  header.site-header .site-header__icons .site-header__icons-wrapper {
      top: -1px;
  }
  .template-collection .collection-btm-desc .faq_heading {
      margin: 15px 0 15px !important;
      width: 100%;
      float: left;
  }
  .collection-btm-desc {
      margin-top: 20px !important;
      margin-bottom: 20px;
  }
  .template-collection .collection-btm-desc img.cb-img {
      margin-top: 30px;
  }
  .filters-toolbar__item-child {
      margin-bottom: 10px;
      justify-content: center;
  }
  .collection_page_header .collection_page_title {
      padding-top: 5px;
      padding-bottom: 5px; 
  }
  .template-collection dl.price.price--listing .price__regular {
      margin-right: 0;
  }
  .collection_spokeNav.grid {
        margin-left: 0;
  }  
  .collection_spokeNav > .grid__item {
      padding-left: 30px;
  }
  .collection_spokeNav  #spokeNavHeader {
      padding: 0 0 0 15px !important;
  }
  .collection_spokeNav #spokeNav .btn.next-arrow {
      left: 15px;
  }
  .collection_spokeNav #spokeNav .btn.prev-arrow {
      right: 5px;
  }
  .collection_spokeNav .slick-slide img {
      margin: 0 auto;
  }
  .collection_spokeNav.grid{
     animation: fadeIn 2s;
    -webkit-animation: fadeIn 2s;
    -moz-animation: fadeIn 2s;
    -o-animation: fadeIn 2s;
    -ms-animation: fadeIn 2s;
  }
}

@media only screen and (min-width: 750px){   
  body.gm-menu-installed .gm-menu ul.gm-grid {
      width: 49%;
      min-width: 49%;
  }
  body.gm-menu-installed .gm-menu {
      justify-content: space-evenly;
  }
  body.gm-menu-installed .gm-menu ul.gm-grid>li:first-child {
      padding-left: 10px;
  }
  body.gm-menu-installed .gm-menu ul.gm-grid>li:last-child {
      padding-right: 10px;
  }
  body.gm-menu-installed .gm-menu ul.gm-grid .gm-image {
      padding: 0 8px 0 0 !important;
  }
  .site-header .h2.site-header__logo {
      border-right: 1px solid #fff;
  }
  body.gm-menu-installed .site-header__logo:before {
      content: none;
      /*height: 60px;    
      right: 0;
      top: 8% !important;*/
  }
  nav#AccessibleNav{
      padding-left: 18px;
      /*width: 49%;*/
  }
  header.site-header .site-header__icons {
     /* width: 26%;
      padding-left: 15px;*/
  }
  header.site-header {
      padding: 0 18px 0 26px;
  }
  .site-header .site-header__cart {
      padding-right: 0px;
  }
  .template-collection .collection_page_title {
      font-size: 35px;
  }
  .template-collection .collection_page_title {
      padding-bottom: 15px;
  }
  .collection_spokeNav .sn-border-right {
      border-right: 5px solid #999999;
  }
  .collection_spokeNav {
      display: flex;
      flex-direction: row;
  }
  .collection_spokeNav.grid{
     animation: fadeIn 2s;
    -webkit-animation: fadeIn 2s;
    -moz-animation: fadeIn 2s;
    -o-animation: fadeIn 2s;
    -ms-animation: fadeIn 2s;
  }
}

@media only screen and (min-width: 990px){
  header.site-header {
      height: 110px;
  }
  header.site-header .gm-item.gm-level-0 {
      height: 110px;
  } 
  .site-header .site-header__icons-wrapper img.flag_logo {
      margin-top: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 999px){
  header.site-header .site-header__logo-image {
       max-width: 250px;
  }
  .site-header .site-header__icons-wrapper img.flag_logo {
      margin-top: 5px;
  }
  .site-header .site-header__cart {
      margin-top: 4px;
      padding: 0 !important;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1342px){
  header.site-header .site-header__logo-image {
       max-width: 250px;
  }
  html > body.gm-menu-installed .gm-menu ul.gm-grid .gm-image {
      padding: 0 !important;
  }
  body.gm-menu-installed .gm-menu ul.gm-grid>li:last-child {
      padding-right: 4px !important;
      margin-left: 5px;
      margin-right: -5px;
  }
}

/* Globo css */

body.gm-menu-installed .gm-menu:not(.gm-menu-mobile){
  position:unset !important;
}

body.gm-menu-installed header.site-header{
  z-index:99999 !important;
}

html body.gm-menu-installed .gm-menu:not(.gm-menu-mobile) > .gm-item.gm-level-0 > .gm-target{
  padding-left:10px !important;
  padding-right:10px !important;
}

html body.gm-menu-installed .gm-menu:not(.gm-menu-mobile){
  justify-content:space-between !important;
}

html body.gm-menu-installed .gm-menu:not(.gm-menu-mobile) > .gm-item.gm-level-0{
  flex:1 !important;
}

html body.gm-menu-installed .gm-menu:not(.gm-menu-mobile) > .gm-item.gm-level-0 > .gm-target > span.gm-text{
  text-align:center !important;
  text-wrap: nowrap !important;
}

html body.gm-menu-installed div#shopify-section-header .gm-menu .gm-item.gm-level-0>.gm-target>span.gm-text {
    letter-spacing: 1.5px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    font-size: 14px!important
}

html body.gm-menu-installed div#shopify-section-header .gm-menu .gm-item > .gm-target > span.gm-text{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}
body.gm-menu-installed .gm-menu ul.gm-grid .gm-image .gm-target.gm-jc-end {
     display: none !important;
}
.gm-menu-installed .gm-menu ul.gm-grid .gm-image>a {
    pointer-events: none !important;
}


.collection_spokeNav .cr-grid .grid__item {
    padding-left: 20px;
}
.collection_spokeNav {
    margin: 28px auto 0px;
}
.collection_spokeNav a {
    font-weight: 600;
    font-size: 14px;
    color: #6B625A;
}
.collection_spokeNav a p{
   color: #6B625A;
}
.collection_spokeNav > .grid__item {
    margin-bottom: 40px;
    padding-left: 15px;
}
.collection_spokeNav #spokeNavHeader {
    padding: 0 0 0 44px;
}
.collection_spokeNav #spokeNav {
    padding: 0 60px 0 75px;
    display: flex;
}
.spokeHeaderItem, .spokeNavItem {
    text-align: center;
}
#spokeNav .btn.next-arrow {
    left: 30px;
}
#spokeNav .btn.prev-arrow {
    right: 0px;
}
#spokeNav .btn.sn-arrow {
    position: absolute;
    z-index: 9;
    top: 40%;
    padding: 0;
    background: transparent;
    width: 32px;
    height: 32px;
    opacity: 1;
    cursor: pointer;
}
#spokeNav .btn.sn-arrow.slick-disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
}
.collection_spokeNav .progress {
    display: block;
    width: 100%;
    height: 4px;
    border-radius: 1px;
    overflow: hidden;  
    background-color: #cccccc;
    background-image: linear-gradient(to right, #cccccc, #cccccc);
    background-repeat: no-repeat;
    background-size: 0 100%;  
    transition: background-size .4s ease-in-out;
    margin-top: 8px;
}
.collection_spokeNav.oc_spokeNav .slick-slide {
    margin: 0 8px;
}
.collection_spokeNav.oc_spokeNav #spokeNavHeader {
    padding: 0 0 0 45px;
}
.product-single__thumbnails.slick-slider {
    display: block;
}
.collection_spokeNav.od_spokeNav #spokeNavHeader {
    padding: 0 0 0 55px;
}
.mobile-nav--close .icon-close {
    display: inline-block !important;
}
.accordion + .container {
    display: none;
}
.accordion.accordion-open + .container {
    display: block;
}
.accordion:not(.accordion-open) span {
    display:block;
    position:absolute;
    left:15px;
    top:30%;
    background:url(/cdn/shop/t/25/assets/plus.png) center center no-repeat;
    padding:10px;
    filter: invert(1);
}
.collection_spokeNav img {
    object-fit: contain;
    width: 100%;
    height: auto;
}
.hidden {
    visibility: hidden;
    position: absolute;
}
.hidden:focus {
    visibility: visible; /* Keep focusable even if hidden */
}
.product-single__thumbnails  .product-single__thumbnails-item {
    display: inline-block !important;
}
.site-footer .footer_bottom_linklist {
    background: #55594F;
}
footer.site-footer ul.footer_bottom_menus li {
    padding-left: 0;
    padding-right: 0;
    margin-left: 20px;
    margin-right: 20px;
}
.index-section a.btn:focus, 
.index-section a.btn:hover, 
.index-section a.btn:active {
    border: 1px solid transparent !important; 
}
.rich-text a.btn:focus, 
.rich-text a.btn:hover, 
.rich-text a.btn:active {
    border: 1px solid transparent !important; 
}
a.btn:focus, 
a.btn:hover, 
a.btn:active {
    border: 1px solid transparent !important; 
}
.quick-view-button a.btn, .quick-view-button .shopify-payment-button a.shopify-payment-button__button--unbranded, 
.shopify-payment-button .quick-view-button a.shopify-payment-button__button--unbranded {
    background: #727869 !important;
}
/*.site-header__logo {
    margin: 20px 0 !important;
}
.site-header > .grid--table > .grid__item {
    float: left;
}*/
.accordion {
    background: #727869 !important;
}
.template-collection .faq-section .faq_heading {
    font-weight: 400 !important;
}
.site-header__icon.site-header__cart #CartCount>span {
    position: relative;
    top: -5px;
}
 

@media only screen and (max-width: 749px) {
   .site-header .site-header__icon.site-header__cart img.cart_logo {
        margin-top: 2px;
    }
   .site-header .site-header__icon {
        padding-top: 3px;
    }
    footer.site-footer ul.footer_bottom_menus li {
        margin: 5px 0 !important;
    }
    footer.site-footer .site-footer__copyright-content {
        display: none;
    }
    #shopify-section-footer footer.site-footer {
        padding-top: 16px !important;
    }
}


@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}




/* @media(min-width:1025px){
  body.gm-menu-installed header.site-header .grid__item.medium-up--one-quarter{
    width: 20% !important;
  }
  
  body.gm-menu-installed header.site-header .grid__item.one-half{
    width: 60% !important;
  }
} */


/* @media(max-width:1579px) and (min-width:1330px){
  body.gm-menu-installed .gm-menu:not(.gm-menu-mobile) > .gm-item.gm-level-0 > .gm-target{
    padding:15px 25px !important;
  }
}

@media(max-width:1329px){
  body.gm-menu-installed .gm-menu:not(.gm-menu-mobile) > .gm-item.gm-level-0 > .gm-target{
    padding:15px 10px !important;
  }

} */




